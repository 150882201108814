@import '../../../styles/common/media_queries';
.drawer_container {
  @include media_query(M) {
    background: none;
  }
  .custom_overlay {
    width: 100%;
    height: 175px;
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    // position: ;
    background-color: rgba(0, 0, 0, 0.47);
    @include media_query(M) {
      display: none;
    }
  }
  .drawer_wrapper {
    // display: none;
    background-color: #fff;
    position: fixed;
    top: 140px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    padding: 35px 0px 0px;
    padding-right: 4px;
    flex-shrink: 0;
    border-left: 1px solid #e4e4e4;
    border-radius: 20px 20px 0px 0px;
    overflow: auto;
    // overflow-y: auto;
    @include media_query(M) {
      width: 500px;
      padding: 45px 0px 0;
      padding-right: 8px;
      right: 0;
      left: auto;
      top: 200px;
      // z-index: initial;
      border-radius: 0;
    }
    @include media_query(L) {
      padding-top: 0;
      height: 850px;
      position: relative;
      top: -50px;
      &.drawerHeight {
        height: calc(100vh - 201px);
      }
    }

    .mob_bar {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 35px;
      background-color: #fafafa;
      border-radius: 20px 20px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .line_bar {
        width: 50px;
        height: 3px;
        background-color: #2c2c2c;
      }
      @include media_query(M) {
        display: none;
      }
    }
    .header {
      display: flex;
      position: relative;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #e4e4e4;
      @include media_query(M) {
        padding-bottom: 24px;
        margin-bottom: 24px;
      }
      .title {
        font-family: 'SourceSansPro';
        color: #2c2c2c;
        font-size: 21px;
        line-height: 24px;
        font-weight: 700;
        @include media_query(M) {
          font-size: 24px;
          line-height: 28px;
        }
      }
      .close_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
        @include media_query(XS) {
          // top: 0px;
          display: none;
        }
        @include media_query(M) {
          display: flex;
          top: 0;
        }
        .close_icon {
          width: 12px;
          height: 12px;
          background-image: url(../../../styles/images/close.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    .middle_container {
      // overflow-y: auto;
      height: 100%;
      padding: 24px 15px 70px;
      margin-bottom: 50px;
      @include media_query(M) {
        padding: 0px 32px 40px 32px;
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: #e4e4e4;
      }
      .edit_btn_wrapper {
        background-color: #38539a;
        border-radius: 24px;
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        cursor: pointer;
        .txt {
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 700;
          color: #ffffff;
          margin-right: 10px;
        }
        .edit_icon {
          width: 16px;
          height: 16px;
          background-image: url(../../../styles/images/edit_aud_group.svg);
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          margin-right: 8px;
        }
      }
      .edit_btn_wrapper_disabled {
        background-color: #d9d9d9;
        border-radius: 24px;
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        cursor: pointer;
        .txt {
          font-family: 'SourceSansPro';
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          color: #5e5e5e;
          margin-right: 10px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .edit_icon {
          width: 16px;
          height: 14px;
          background-image: url(../../../styles/images/disabled_edit_agroup.svg);
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
          margin-right: 8px;
        }
      }
      .course_title {
        font-family: 'SourceSansPro';
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #2c2c2c;
        margin-bottom: 15px;
        @include media_query(M) {
          font-family: 'SourceSansPro';
          font-size: 24px;
          line-height: 28px;
        }
      }
      .description_wrapper {
        margin-bottom: 12px;
        @include media_query(M) {
          line-height: 1.2;
          margin-bottom: 16px;
        }
        .label {
          font-family: 'SourceSansPro';
          // font-size: 14px;
          color: #2c2c2c;
          font-weight: 700;
          margin-bottom: 4px;
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 16px;
            line-height: 24px;
          }
        }
        .value {
          font-family: 'SourceSansPro';
          font-size: 14px;
          line-height: 18px;
          color: #2c2c2c;
          font-weight: 400;
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .targ_wrapper {
        margin-bottom: 32px;
        .description_wrapper {
          margin: 0;
          .label {
            margin: 0;
          }
        }
        .pills_container {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          padding: 0;
          @include media_query(M) {
            margin-bottom: 0;
          }
        }
      }

      .upload_details_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 12px;
        @include media_query(M) {
          margin-bottom: 16px;
        }

        .upload_info {
          flex: 33.33%;
          max-width: 33.33%;
          padding: 0 15px;
          border-left: 1px solid #e4e4e4;

          &:first-child {
            padding-left: 0;
            border: none;
          }
          &:last-child {
            padding-right: 0;
          }
          .title {
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 700;
            color: #2c2c2c;
            margin-bottom: 8px;
            @include media_query(M) {
              font-family: 'SourceSansPro';
              font-size: 16px;
            }
          }
          .info_text {
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 500;
            color: #707070;
            @include media_query(M) {
              font-family: 'SourceSansPro';
              font-size: 16px;
            }
          }
        }
      }
      .note_wrapper {
        width: 100%;
        background-color: #fff9f0;
        border-radius: 12px;
        padding: 12px;
        margin-bottom: 24px;
        font-family: 'SourceSansPro';
        @include media_query(M) {
          margin-bottom: 32px;
        }
        .note_txt {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          font-style: italic;
          color: #2c2c2c;
          .semi_bold_txt {
            font-weight: 500;
          }
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .img_instances_container {
        &.lp_user_grp_mgt {
          margin-top: 24px;
          .dropdown_container {
            margin-bottom: 25px;
          }
          .add_course_btn_wrapper {
            height: auto;
            margin-bottom: 24px;
            margin-top: 24px;
            position: relative;
            box-shadow: none;
            padding: 0;
            .txt {
              @include media_query(XS) {
                font-size: 14px;
              }
              @include media_query(M) {
                font-size: 16px;
              }
            }
          }
        }
        .insturction_text_wrapper {
          display: flex;
          align-items: center;
          justify-content: end;
          margin-bottom: 12px;
          .info_text {
            font-family: 'SourceSansPro';
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #2c2c2c;
            padding-right: 10px;
            cursor: pointer;
            @include media_query(M) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .left_arrow_icon {
            width: 12px;
            height: 18px;
            background-image: url(../../../styles/images/left_arrow_black.svg);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
          }
        }
        .dropdown_container {
          // padding: 20px 0px;
          margin-bottom: 50px;
          border: 1px solid #e4e4e4;
          border-radius: 10px;
          position: relative;
          .top_bar {
            width: 100%;
            height: 50px;
            background-color: #f0f0f0;
            border-radius: 10px 10px 0 0;
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 20px;
            cursor: pointer;
            .title {
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
              padding-right: 12px;

              @include media_query(M) {
                font-size: 18px;
                line-height: 21px;
              }
            }
            .info_btn_wrapper {
              position: relative;

              &.isClicked {
                .course_type_read_banner {
                  display: block;
                }
              }
              .info_btn {
                width: 20px;
                height: 20px;
                background-image: url(../../../styles/images/info_button.svg);
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
              }

              .course_type_read_banner {
                display: none;
                position: absolute;
                top: 28px;
                right: -15px;
                width: 180px;
                min-height: 145px;
                background-color: #ffffff;
                border-radius: 12px;
                padding: 16px 5px 16px 16px;
                box-shadow: 0px 2px 16px rgb(44 44 44 / 10%);
                z-index: 1;
                .tooltip {
                  position: absolute;
                  top: -8px;
                  right: 18px;
                  width: 15px;
                  height: 14px;
                  background-image: url(../../../styles/images/tooltip_white_icon.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
                .title {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 700;
                  color: #2c2c2c;
                  margin-bottom: 10px;
                  @include media_query(M) {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
                .course_types {
                  .banner_details {
                    max-width: 100%;
                    margin-bottom: 12px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                    .item {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      @include media_query(M) {
                        font-size: 16px;
                        line-height: 21px;
                      }
                    }
                  }
                }
              }
            }

            .toggle_arrow {
              position: absolute;
              right: 20px;
              width: 12px;
              height: 18px;
              background-repeat: no-repeat;
              background-size: contain;
              &.big_icon {
                &.up_arrow {
                  width: 12px;
                  height: 18px;
                }
              }
              &.up_arrow {
                transform: rotate(90deg);
                background-image: url(../../../styles/images/left_arrow_black.svg);
              }
              &.down_arrow {
                transform: rotate(270deg);
                background-image: url(../../../styles/images/left_arrow_black.svg);
              }
            }
          }
          .list_wrapper {
            // position: absolute;
            top: 100%;
            width: 100%;
            height: auto;
            .list {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 20px;
              width: 100%;
              min-height: 65px;
              border-bottom: 1px solid #e4e4e4;
              &:last-child {
                border: none;
              }

              .instance_status {
                min-width: 95px;
                height: 25px;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                font-family: 'SourceSansPro';
                @include media_query(M) {
                  font-size: 16px;
                }
                &.live_sts {
                  color: #107f47;
                  background-color: #f0fae5;
                }
                &.draft_sts {
                  color: #a36a00;
                  background-color: #fff9f0;
                }
                &.offline_sts {
                  color: #62625a;
                  background-color: #f0f0f0;
                }
                &.go_live_sts {
                  color: #38539a;
                  background-color: #f1f2ff;
                }
                &.archive_sts {
                  color: #7f3e3e;
                  background-color: #ffe2e2;
                }
              }
            }
          }
        }
      }

      .btn_wrapper {
        display: flex;
        font-family: 'SourceSansPro';
        flex-direction: column;
        justify-content: center;
        padding: 0;
        margin-bottom: 20px;
        &.disabled {
          pointer-events: none;
          .btn {
            background-color: #d9d9d9;
            color: #5e5e5e;
            border: transparent;
          }
          .delete_icon {
            background-image: url(../../../styles/images/delete-black.svg);
          }
        }
        &.justify_left {
          justify-content: left;
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          height: 35px;
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 700;
          // background-color: #d9d9d9;
          border: 2px solid #2c2c2c;
          color: #2c2c2c;
          border-radius: 24px;
          margin: 0 6px 0;
          padding: 0 30px;
          white-space: nowrap;
          cursor: pointer;
        }
        .delete_icon {
          width: 16px;
          height: 18px;
          background-image: url(../../../styles/images/delete-black-update.svg);
          background-repeat: no-repeat;
          background-size: cover;
          margin: 0 10px;
        }
        &:hover {
          .btn {
            background-color: #d91734;
            color: #fff;
            border: 2px solid #d91734;
          }
          .delete_icon {
            background-image: url(../../../styles/images/delete-white.svg);
          }
        }
      }
      .suggestion_text {
        font-family: 'SourceSansPro';
        font-size: 14px;
        color: #a36a00;
        min-height: 50px;
        font-weight: 400;
        background: #fff9f0;
        border: 1px solid #d1b278;
        border-radius: 12px;
        padding: 10px;
        margin-bottom: 20px;
      }
      .add_course_btn_wrapper {
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 15px;
        background-color: #ffffff;
        height: 70px;
        display: flex;
        align-items: center;
        box-shadow: 0px -1px 10px rgba(44, 44, 44, 0.25);
        @include media_query(M) {
          padding: 0;
          position: relative;
          margin-bottom: 25px;
          box-shadow: none;
        }
        .add_course_ag_btn {
          width: 100%;
          border: 2px solid #2c2c2c;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'SourceSansPro';
          height: 36px;
          border-radius: 100px;
          cursor: pointer;
          .plus_icon {
            width: 15px;
            height: 15px;
            background-image: url('../../../styles/images/plus_icon_aud.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 8px;
          }
          .txt {
            font-family: 'SourceSansPro';
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
            @include media_query(M) {
              // font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

      .deleteUserGroupBtn {
        background: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 32px;
        border-radius: 100px;
        border: 2px solid #7f3e3e;
        font-size: 16px;
        font-weight: 700;
        color: #7f3e3e;
        cursor: pointer;
        outline: none;
        margin-bottom: 24px;

        &.disabled {
          pointer-events: none;
          background-color: #d9d9d9;
          color: #5e5e5e;
          border: none;
        }

        .deleteIcon {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 8px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;

          &.active {
            background-image: url('../../../styles/images/delete_user_group_red.svg');
          }

          &.disabled {
            background-image: url('../../../styles/images/delete_user_group_disabled.svg');
          }
        }
      }

      .deleteUserGroupInfo {
        margin: 0 0 24px 0;
        font-weight: 400;
        line-height: 24px;
      }
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
  }
}

.item_wrapper {
  display: flex;
  align-items: center;
  max-width: 70%;
  overflow: hidden;
  &.mt_instances {
    align-items: flex-start;
    .course_type_icon {
      margin-top: 5px;
    }
  }
  .item {
    font-family: 'SourceSansPro';
    font-size: 14px;
    font-weight: 500;
    color: #2c2c2c;
    @include media_query(M) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.course_type_icon {
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;

  &.gen_visiblity_icon {
    width: 14px;
    height: 18px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/gen_visibility_filled.svg);
  }
  &.qstart_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/qstart_icon_filled.svg);
  }
  &.req_icon {
    width: 18px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/req_icon_filled.svg);
  }
  &.recom_icon {
    width: 18px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/recom_icon_filled.svg);
  }
}

.download_btn_wrapper {
  border-radius: 24px;
  border: 2px solid #2c2c2c;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  font-weight: 700;
  gap: 8px;
  cursor: pointer;
  .txt {
    font-family: 'SourceSansPro';
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
    color: #ffffff;
    margin-right: 10px;
  }
  .link_icon {
    width: 16px;
    height: 16px;
    background-image: url(../../../styles/images/edit_aud_group.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin-right: 8px;
  }
}

.attachment {
  width: 16px;
  height: 18px;
  background-image: url(../../../styles/images/attachment.svg);
  background-repeat: no-repeat;
}

.edit_group {
  width: 24px;
  height: 28px;
  margin-left: 12px;
  margin-top: 3px;
  cursor: pointer;
  background-image: url(../../../styles/images/edit_icon_black.svg);
  background-repeat: no-repeat;
  background-size: contain; /* This will scale the SVG to fit within the container */
}

.edit_header_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-family: 'SourceSansPro';
  align-items: center;
  justify-content: space-between;

  &.padding_class {
    @include media_query(L) {
      padding-top: 40px;
    }
  }
  @include media_query(XS) {
    padding-top: 0px;
  }
  @include media_query(M) {
    flex-direction: row;
    align-items: center;
  }
  @include media_query(L) {
  }

  .label_text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #2c2c2c;
    white-space: nowrap;
    @include media_query(M) {
      font-size: 16px;
    }
  }

  .tag_text {
    font-family: 'SourceSansPro';
    font-size: 14px;
    color: #707070;
    @include media_query(M) {
      font-size: 16px;
    }
  }

  @include media_query(M) {
    font-size: 16px;
  }
}

.input {
  font-family: 'SourceSansPro';
  width: 100%;
  height: 35px;
  border: 2px solid #2c2c2c;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #2c2c2c;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  // text-transform: capitalize;
  &::placeholder {
    color: #707070;
    font-weight: 400;
  }
  @include media_query(M) {
    font-size: 16px;
    line-height: 21px;
  }
}

.edit_btn {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .cancel_btn {
    border-radius: 24px;
    border: 2px solid #2c2c2c;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    font-weight: 700;
    gap: 8px;
    cursor: pointer;
  }
  .save_btn {
  border-radius: 24px;
  border: 2px solid #2c2c2c;
  height: 48px;
  display: flex;
  align-items: center;
  background: #2c2c2c;
  padding: 0 20px;
  color: #ffffff;
  justify-content: center;
  font-weight: 700;
  gap: 8px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background-color: #d9d9d9;
    color: #5e5e5e;
    border: none;
  }
}
}

.separator {
  border-top: 1px solid #e4e4e4;
}

.delete_btn_wrapper {
  border-radius: 24px;
  border: 2px solid #7f3e3e;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 700;
  gap: 8px;
  cursor: pointer;
  .txt {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #7f3e3e;
    margin-right: 10px;
  }
  .delete_icon {
    width: 14px;
    height: 16px;
    background-image: url(../../../styles/images/delete_red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}

.replace {
  display: flex;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #38539a;
  font-weight: 700;
  cursor: pointer;
  @media (max-width: 768px) {
    // Targeting small screens
    justify-content: center;
    flex-wrap: wrap;
  }
}

.confirm_txt {
  margin-bottom: 24px;
}

.edit_header_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-family: 'SourceSansPro';
  align-items: center;
  justify-content: space-between;

  &.padding_class {
    @include media_query(L) {
      padding-top: 40px;
    }
  }
  @include media_query(XS) {
    padding-top: 0px;
  }
  @include media_query(M) {
    flex-direction: row;
    align-items: center;
  }
  @include media_query(L) {
  }

  .label_text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #2c2c2c;
    white-space: nowrap;
    @include media_query(M) {
      font-size: 16px;
    }
  }

  .tag_text {
    font-family: 'SourceSansPro';
    font-size: 14px;
    color: #707070;
    @include media_query(M) {
      font-size: 16px;
    }
  }

  @include media_query(M) {
    font-size: 16px;
  }
}

.input {
  font-family: 'SourceSansPro';
  width: 100%;
  height: 35px;
  border: 2px solid #2c2c2c;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #2c2c2c;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  // text-transform: capitalize;
  &::placeholder {
    color: #707070;
    font-weight: 400;
  }
  @include media_query(M) {
    font-size: 16px;
    line-height: 21px;
  }
}

.edit_btn {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .cancel_btn {
    border-radius: 24px;
    border: 2px solid #2c2c2c;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    font-weight: 700;
    gap: 8px;
    cursor: pointer;
  }
  .save_btn {
  border-radius: 24px;
  border: 2px solid #2c2c2c;
  height: 48px;
  display: flex;
  align-items: center;
  background: #2c2c2c;
  padding: 0 20px;
  color: #ffffff;
  justify-content: center;
  font-weight: 700;
  gap: 8px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background-color: #d9d9d9;
    color: #5e5e5e;
    border: none;
  }
}
}

.separator {
  border-top: 1px solid #e4e4e4;
}

.delete_btn_wrapper {
  border-radius: 24px;
  border: 2px solid #7f3e3e;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 700;
  gap: 8px;
  cursor: pointer;
  .txt {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #7f3e3e;
    margin-right: 10px;
  }
  .delete_icon {
    width: 14px;
    height: 16px;
    background-image: url(../../../styles/images/delete_red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}

.replace {
  display: flex;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #38539a;
  font-weight: 700;
  cursor: pointer;
  @media (max-width: 768px) {
    // Targeting small screens
    justify-content: center;
    flex-wrap: wrap;
  }
}

.confirm_txt {
  margin-bottom: 24px;
}
