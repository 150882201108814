
.deleteUserGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    padding: 12px 0;
    color: #7F3E3E;
    border: 2px solid #7F3E3E;
    background: none;
    border-radius: 100px;
    font-weight: 700;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      background-color: #d9d9d9;
      color: #5e5e5e;
      border: none;
    }

    .deleteIcon {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../..//styles/images/delete_user_group_red.svg');
        &.disabled {
          background-image: url('../../..//styles/images/delete_user_group_disabled.svg');
        }
      }
}

.deleteInfo {
  margin: 24px 0;
  font-size: 16px;
  font-style: italic;
}
